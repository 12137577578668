export default {
    data() {
        return {
            datas: {
                loading: false,
                page: 0,
                data: []
            },
            cfg: {}
        }
    },
    created() {
        this._config(['app_name4', 'logo4']);
    },
    mounted() {
        // 监听滚动事件
        window.addEventListener('scroll', this.handleScroll);
        // 初始加载数据
        this._load();
    },
    beforeDestroy() {
        // 在组件销毁时移除滚动事件监听
        window.removeEventListener('scroll', this.handleScroll);
    },
    methods: {
        handleScroll() {
            const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
            const windowHeight = window.innerHeight;
            const scrollHeight = document.documentElement.scrollHeight;
            if (scrollTop + windowHeight >= scrollHeight - 50 && !this.loading) {
                // 滚动到底部，触发加载更多
                this._load();
            }
        },
        _load() {
            if (this.datas.loading) {
                return;
            }
            this.datas.loading = true;
            this.$http.post('log/list', {
                page: this.datas.page + 1,
                order: {
                    qi: 'desc'
                }
            }).then(res => {
                this.datas.loading = false;
                if (res.code) {
                    this.datas.data = this.datas.data.concat(res.data.data);
                    this.datas.page = res.data.current_page;
                }
            })
        }
    }
}