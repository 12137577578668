import {
    mapState
} from 'vuex'
import store from "@/store/"
// 尝试将用户在根目录中的store/index.js的vuex的state变量，全部加载到全局变量中
let $uStoreKey = [];
try {
    $uStoreKey = store.state ? Object.keys(store.state) : [];
} catch (e) { }
let mixins = {
    data() {
        return {
            datas: {
                data: [],
                total: 0,
                page: 0,
                limit: 20,
                order: {
                    id: 'desc'
                },
                add: {
                    kw: ''
                },
            },
            r: {},
            show_save: false,
            cfg: {},
            mix_fields: []
        };
    },
    computed: {
        ...mapState($uStoreKey),
    },
    watch: {
        'datas.add': {
            deep: true,
            handler() {
                // this._load(1);
            }
        }
    },
    created() {
        this.$vuex = (name, value) => {
            this.$store.commit('$uStore', {
                name,
                value
            })
        };
    },
    methods: {
        _bu_num(num) {
            if (num < 10) {
                return '0' + num;
            }
            return num;
        },




        _get_num_info(num, year = 2023, qi = '') {
            num = Number(num);
            var res = {};




            if (Number(year) == 2024 && Number(qi) >= 41) {
                if ([5, 17, 29, 41].indexOf(num) >= 0) {
                    res.shu = '鼠';
                } else if ([4, 16, 28, 40].indexOf(num) >= 0) {
                    res.shu = '牛';
                } else if ([3, 15, 27, 39].indexOf(num) >= 0) {
                    res.shu = '虎';
                } else if ([2, 14, 26, 38].indexOf(num) >= 0) {
                    res.shu = '兔';
                } else if ([1, 13, 25, 37, 49].indexOf(num) >= 0) {
                    res.shu = '龙';
                } else if ([12, 24, 36, 48].indexOf(num) >= 0) {
                    res.shu = '蛇';
                } else if ([11, 23, 35, 47].indexOf(num) >= 0) {
                    res.shu = '马';
                } else if ([10, 22, 34, 46].indexOf(num) >= 0) {
                    res.shu = '羊';
                } else if ([9, 21, 33, 45].indexOf(num) >= 0) {
                    res.shu = '猴';
                } else if ([8, 20, 32, 44].indexOf(num) >= 0) {
                    res.shu = '鸡';
                } else if ([7, 19, 31, 43].indexOf(num) >= 0) {
                    res.shu = '狗';
                } else if ([6, 18, 30, 42].indexOf(num) >= 0) {
                    res.shu = '猪';
                }


                if ([2, 3, 10, 11, 24, 25, 32, 33, 40, 41].indexOf(num) >= 0) {
                    res.wu = '金';
                } else if ([6, 7, 14, 15, 22, 23, 36, 37, 44, 45].indexOf(num) >= 0) {
                    res.wu = '木';
                } else if ([12, 13, 20, 21, 28, 29, 42, 43].indexOf(num) >= 0) {
                    res.wu = '水';
                } else if ([1, 8, 9, 16, 17, 30, 31, 38, 39, 46, 47].indexOf(num) >= 0) {
                    res.wu = '火';
                } else if ([4, 5, 18, 19, 26, 27, 34, 35, 48, 49].indexOf(num) >= 0) {
                    res.wu = '土';
                }
            }

            else {
                if ([4, 16, 28, 40].indexOf(num) >= 0) {
                    res.shu = '鼠';
                } else if ([3, 15, 27, 39].indexOf(num) >= 0) {
                    res.shu = '牛';
                } else if ([2, 14, 26, 38].indexOf(num) >= 0) {
                    res.shu = '虎';
                } else if ([1, 13, 25, 37, 49].indexOf(num) >= 0) {
                    res.shu = '兔';
                } else if ([12, 24, 36, 48].indexOf(num) >= 0) {
                    res.shu = '龙';
                } else if ([11, 23, 35, 47].indexOf(num) >= 0) {
                    res.shu = '蛇';
                } else if ([10, 22, 34, 46].indexOf(num) >= 0) {
                    res.shu = '马';
                } else if ([9, 21, 33, 45].indexOf(num) >= 0) {
                    res.shu = '羊';
                } else if ([8, 20, 32, 44].indexOf(num) >= 0) {
                    res.shu = '猴';
                } else if ([7, 19, 31, 43].indexOf(num) >= 0) {
                    res.shu = '鸡';
                } else if ([6, 18, 30, 42].indexOf(num) >= 0) {
                    res.shu = '狗';
                } else if ([5, 17, 29, 41].indexOf(num) >= 0) {
                    res.shu = '猪';
                }


                if ([1, 2, 9, 10, 23, 24, 31, 32, 39, 40].indexOf(num) >= 0) {
                    res.wu = '金';
                } else if ([5, 6, 13, 14, 21, 22, 35, 36, 43, 44].indexOf(num) >= 0) {
                    res.wu = '木';
                } else if ([11, 12, 19, 20, 27, 28, 41, 42, 49].indexOf(num) >= 0) {
                    res.wu = '水';
                } else if ([7, 8, 15, 16, 29, 30, 37, 38, 45, 46].indexOf(num) >= 0) {
                    res.wu = '火';
                } else if ([3, 4, 17, 18, 25, 26, 33, 34, 47, 48].indexOf(num) >= 0) {
                    res.wu = '土';
                }
            }




            if ([1, 7, 13, 19, 23, 29, 35, 45, 2, 8, 12, 18, 24, 30, 34, 40, 46].indexOf(num) >= 0) {
                res.c = 'red';
            } else if ([3, 9, 15, 25, 31, 37, 41, 47, 4, 10, 14, 20, 26, 42, 36, 48].indexOf(num) >= 0) {
                res.c = 'blue';
            } else if ([5, 11, 17, 21, 27, 33, 39, 43, 49, 6, 16, 22, 28, 32, 38, 44].indexOf(num) >= 0) {
                res.c = 'green';
            }
            if (['兔', '虎', '龙'].indexOf(res.shu) >= 0) {
                res.ji = '春';
            }
            else if (['马', '蛇', '羊'].indexOf(res.shu) >= 0) {
                res.ji = '夏';
            }
            else if (['鸡', '猴', '狗'].indexOf(res.shu) >= 0) {
                res.ji = '秋';
            }
            else {
                res.ji = '冬';
            }
            if (['兔', '鸡', '蛇'].indexOf(res.shu) >= 0) {
                res.qq = '琴';
            }
            else if (['鼠', '牛', '狗'].indexOf(res.shu) >= 0) {
                res.qq = '棋';
            }
            else if (['马', '虎', '龙'].indexOf(res.shu) >= 0) {
                res.qq = '书';
            }
            else {
                res.qq = '画';
            }
            if (['虎', '蛇', '龙', '狗'].indexOf(res.shu) >= 0) {
                res.rou = '肉';
            }
            else if (['猪', '鼠', '鸡', '猴'].indexOf(res.shu) >= 0) {
                res.rou = '菜';
            }
            else {
                res.rou = '草';
            }
            if (['兔', '马', '猴', '猪', '牛', '龙'].indexOf(res.shu) >= 0) {
                res.td = 1;
            } else {
                res.td = 0;
            }
            if (['虎', '兔', '龙', '蛇'].indexOf(res.shu) >= 0) {
                res.guo = '吴';
            }
            else if (['马', '羊', '鸡', '猴'].indexOf(res.shu) >= 0) {
                res.guo = '蜀';
            }
            else {
                res.guo = '魏';
            }
            if (['虎', '兔', '龙'].indexOf(res.shu) >= 0) {
                res.tq = '风';
            }
            else if ([
                '蛇',
                '马',
                '羊',
            ].indexOf(res.shu) >= 0) {
                res.tq = '雨';
            }
            else if (['猴', '鸡', '狗'].indexOf(res.shu) >= 0) {
                res.tq = '雷';
            }
            else {
                res.tq = '电';
            }



            if (['马', '兔', '鼠', '鸡'].indexOf(res.shu) >= 0) {
                res.color = '红';
            }
            else if (['蛇', '虎', '猪', '猴'].indexOf(res.shu) >= 0) {
                res.color = '蓝';
            }
            else {
                res.color = '绿';
            }

            if ([1, 2, 7, 8, 12, 13, 18, 19, 23, 24, 29, 30, 34, 35, 40, 45, 46].indexOf(num) >= 0) {
                res.bo = '红';
            }
            else if ([
                3,
                4,
                9,
                10,
                14,
                15,
                20,
                25,
                26,
                31,
                36,
                37,
                41,
                42,
                47,
                48,
            ].indexOf(num) >= 0) {
                res.bo = '蓝';
            } else {
                res.bo = '绿';
            }
            return res;
        },
        _reset(field) {
            this[field] = this.$options.data()[field];
        },
        _table_time(row, column, cellValue, index) {
            // console.log(row, column, cellValue, index);
            return this._time_format(cellValue, 'yyyy-mm-dd hh:MM');
        },
        _time_format(dateTime = null, fmt = 'yyyy-mm-dd hh:MM:ss') {
            // 如果为null,则格式化当前时间
            if (!dateTime) dateTime = Number(new Date())
            // 如果dateTime长度为10或者13，则为秒和毫秒的时间戳，如果超过13位，则为其他的时间格式
            if (dateTime.toString().length == 10) dateTime *= 1000
            const date = new Date(dateTime)
            let ret
            const opt = {
                'y+': date.getFullYear().toString(), // 年
                'm+': (date.getMonth() + 1).toString(), // 月
                'd+': date.getDate().toString(), // 日
                'h+': date.getHours().toString(), // 时
                'M+': date.getMinutes().toString(), // 分
                's+': date.getSeconds().toString() // 秒
                // 有其他格式化字符需求可以继续添加，必须转化成字符串
            }
            for (const k in opt) {
                ret = new RegExp(`(${k})`).exec(fmt)
                if (ret) {
                    fmt = fmt.replace(ret[1], (ret[1].length == 1) ? (opt[k]) : (opt[k].padStart(ret[1].length, '0')))
                }
            }
            return fmt
        },
        _msg(msg, option = {
            code: 1
        }) {
            if (option.code) {
                this.$message.success(msg);
            } else {
                this.$message.error(msg);
            }
        },
        _do_more(res) {
            let arr = res.split('|');
            if (arr.length == 2) {
                this._quick_edit(this.table, this.ids, arr[0], arr[1]);
                this._load();
            }
        },
        _total(scene = '', option = {}) {
            this.$http.post(`index/total?scene=${scene}`, option).then(res => {
                this.total = res.data;
            });
        },
        _to_url(url) {
            this.$router.push(url);
        },
        _favicon(faviconurl) {
            var link = document.querySelector("link[rel*='icon']") || document.createElement('link');
            link.type = 'image/x-icon';
            link.rel = 'icon';
            link.href = faviconurl;
            document.getElementsByTagName('head')[0].appendChild(link);
        },
        _admin(data = {}, callback) {
            this.$http.post('admin/my', data).then(res => {
                this.admin = res.data;
                if (callback) {
                    callback(res.data);
                }
            })
        },
        _map() {
            let map = this.map = new BMap.Map('map_box');
            map.centerAndZoom(this.cfg.app_city, 15);
            map.enableScrollWheelZoom(true);
            return map;
        },
        _out_excel() {
            this.$http.post('index/out_excel', {
                table: this.table,
                ...this.datas.order
            }, {
                responseType: 'blob',
            }).then(res => {
                let url = window.URL.createObjectURL(new Blob([res], {
                    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8'
                }))
                let link = document.createElement('a')
                link.style.display = 'none'
                link.href = url;
                let excelName = `下载.xlsx`;
                link.setAttribute('download', excelName)
                document.body.appendChild(link)
                link.click()
                link.remove()
            })
        },
        _quick_edit(table, id, field, value, type = 0) {
            return new Promise(s => {
                this.$http.post('index/quick_edit', {
                    table: table,
                    id: id,
                    field: field,
                    value: value
                }).then(res => {
                    if (res.code) {
                        this._load(type);
                        s(res);
                    }
                })
            })
        },
        _quick_edit_ok(table, id, field, value) {
            this.$confirm('确定操作吗?', '').then(res => {
                return new Promise(s => {
                    this.$http.post('index/quick_edit', {
                        table: table,
                        id: id,
                        field: field,
                        value: value
                    }).then(res => {
                        if (res.code) {
                            this._load(1);
                            s(res);
                        }
                    })
                })
            });
        },
        _files(files, field = '') {
            if (files == undefined) {
                return [];
            }
            let res = [];
            for (let i = 0; i < files.length; i++) {
                if (files[i].field == field) {
                    res.push(files[i]);
                }
            }
            return res;
        },
        _load(type = 0) {
            if (type == 1) {
                this.datas.page = 0;
                this.datas.status = 0;
            }
            if (this.datas.status == -1) {
                return;
            }
            this.datas.status = -1;
            let http = this.datas.url || `${this.table}/list`;
            if (!http) {
                return;
            }
            let page = this.datas.page;
            this.$http.post(http, Object.assign({}, {
                page: page,
                order: this.datas.order,
                limit: this.datas.limit,
                with_count: this.datas.with_count || [],
                append: this.datas.append || [],
            }, this.datas.add)).then(res => {
                this.datas.status = 0;
                let add = (res.data || {}).data || [];
                this.datas.data = add;
                this.datas.total = res.data.total || 0;
                this.datas.page = res.data.current_page;
            });
        },
        _config(fields = [], callback) {
            this.$http.post('config/index', {
                fields: fields
            }).then(res => {
                this.cfg = res.data;
                if (callback) {
                    callback(res);
                }
            });
        },
        _save(id = 0, data = {}, append = [], callback) {
            if (this.show_save !== undefined) {
                this.show_save = true;
            }
            if (this.r_is_reset !== false) {
                this.r = this.$options.data().r;
            }
            if (id > 0) {
                this.$http.post(`${this.table}/show`, {
                    id: id,
                    append: [
                        ...this.r_append || [],
                        ...append || [],
                        ...data.append || []
                    ],
                    with_count: this.r_with_count
                }).then(res => {
                    if (res.code) {
                        this.r = res.data;
                    }
                    if (callback) {
                        callback(res.data);
                    }
                });
            } else {
                this.r = {
                    ...this.r,
                    ...data
                };
            }
        },
        _read(id, data = {}, table = '') {
            this.$http.post(`${this.table}/show`, {
                id: id,
                append: this.r_append
            }).then(res => {
                this.r = {
                    ...this.$options.data().r,
                    ...res.data,
                    ...data
                };
            });
        },
        _submit(opt = {}) {
            if (opt.is_val) {
                var val = true;
                this.$refs.r.validate(validate => {
                    if (validate) {
                        val = true;
                    } else {
                        val = false;
                    }
                })
            } else {
                val = true;
            }
            if (val) {
                let action = 'add';
                if (this.r.id) {
                    action = 'edit';
                }
                this.$http.post(`${this.table}/${action}`, {
                    ...this.r,
                    ...this.r_add
                })
                    .then(res => {
                        if (res.code) {
                            this.show_save = false;
                            this.r = this.$options.data().r;
                            this._load(1);
                            // location.reload();
                        }
                    });
            }
        },
        _del(id = 0) {
            // this.$http.post(`${this.table}/del`, {
            //     id: id
            // }).then(res => {
            //     this._load();
            // });
            // return;
            this.$confirm('此操作将删除该数据, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$http.post(`${this.table}/del`, {
                    id: id
                }).then(res => {
                    this._load(1);
                });
            }).catch(() => {
            });
        },
        _select(val) {
            this.ids = [];
            for (let i = 0; i < val.length; i++) {
                this.ids.push(val[i].id);
            }
        },
        _size(val) {
            this.datas.limit = val;
            this._load(1);
        },
        _page(val) {
            this.datas.page = val;
            this._load();
        },
        _sort(obj) {
            let type = "desc";
            if (obj.order == "ascending") {
                type = "asc";
            }
            //  排序字段主要  group.title   user_group_id
            if (obj.column.sortBy !== undefined) {
                this.datas.order = obj.column.sortBy + " " + type;
            } else {
                this.datas.order = obj.prop + " " + type;
            }
            this.datas.page = 1;
            this._load(1);
        },
        // 影藏字符串
        _hide_str(str, frontLen, endLen) {
            var newStr;
            var len = str.length - frontLen - endLen;
            if (str.length === 2) {
                newStr = str.substring(0, 1) + '*';
            } else if (str.length > 2) {
                var char = '';
                for (let i = 0; i < len; i++) {
                    char += '*';
                }
                newStr = str.substring(0, frontLen) + char + str.substring(str.length - endLen);
            } else {
                newStr = str;
            }
            return newStr;
        }
    }
}
export default mixins
